import React, { FunctionComponent, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import HeaderLinks from "../HeaderLinks/HeaderLinks"
import { THeaderLink } from "../../models/header.model"
import MobileMenu from "../MobileMenu/MobileMenu"
import { Link } from "gatsby"
import { Drawer } from "@material-ui/core"

const headerLinks: THeaderLink[] = [
  {
    name: "Home",
    url: "/",
    isGatsbyLink: true,
  },
  {
    name: "Kontakt",
    url: "/contact",
    isGatsbyLink: true,
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/britta-daffner",
  },
  {
    name: "Jetzt bestellen",
    class: " font-serif",
    url:
      "https://www.amazon.de/Die-Disruptions-DNA-Schl%C3%BCsselfaktoren-Erfolg-Digitalisierung/dp/3752688351",
  },
]

const Header: FunctionComponent = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <header className="fixed top-0 z-10 flex w-full text-gray-700 bg-white shadow-sm body-font">
      <div className="container flex flex-row items-center p-6 mx-auto">
        <Link
          to={"/"}
          className="flex items-center font-medium text-gray-900 title-font"
        >
          <p className="font-serif text-2xl text-gray-900 text-bold">
            Die Disruptions-DNA
          </p>
        </Link>
        <nav className="flex items-center justify-center ml-auto text-base">
          <div className="hidden md:block">
            <HeaderLinks
              links={headerLinks}
              linkClasses={"mr-5 font-medium hover:text-gray-900"}
            />
          </div>
          <button
            onClick={() => {
              setMenuOpen((isMenuOpen) => !isMenuOpen)
            }}
            className="text-base md:hidden "
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <Drawer
            anchor={"right"}
            open={isMenuOpen}
            onClose={(isMenuOpen) => !isMenuOpen}
          >
            <MobileMenu handleClose={setMenuOpen} />
          </Drawer>
        </nav>
      </div>
    </header>
  )
}

export default Header

import React, { FunctionComponent } from "react"
import { THeaderLink } from "../../models/header.model"
import { Link } from "gatsby"

type Props = {
  link: THeaderLink
  linkClass: string
}

const HeaderLink: FunctionComponent<Props> = ({ link, linkClass }) => {
  return (
    <>
      {link.isGatsbyLink ? (
        <Link to={link.url} className={linkClass}>
          {link.name}
        </Link>
      ) : (
        <a href={link.url} rel="noreferrer" className={linkClass}>
          {link.name}
        </a>
      )}
    </>
  )
}

export default HeaderLink

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import React, { FunctionComponent } from "react"
import HeaderLinks from "../HeaderLinks/HeaderLinks"
import { THeaderLink } from "../../models/header.model"

const headerLinks: THeaderLink[] = [
  {
    name: "Home",
    url: "/",
    isGatsbyLink: true,
  },
  {
    name: "Kontakt",
    url: "/contact",
    isGatsbyLink: true,
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/britta-daffner",
  },
  {
    name: "Jetzt bestellen",
    class: " font-serif",
    url:
      "https://www.amazon.de/Die-Disruptions-DNA-Schl%C3%BCsselfaktoren-Erfolg-Digitalisierung/dp/3752688351",
  },
]

type Props = {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileMenu: FunctionComponent<Props> = ({ handleClose }) => {
  return (
    <>
      <div className="flex items-center justify-between px-5 pt-4 w-60">
        <div>
          <p className="font-serif text-bold">Disruptions-DNA</p>
        </div>
        <div className="-mr-2">
          <button
            type="button"
            onClick={() => handleClose(false)}
            className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Close main menu</span>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
        <div className="px-2 pt-2 pb-3 space-y-1" role="none">
          <HeaderLinks
            links={headerLinks}
            linkClasses={
              "block px-3 py-2 rounded-md text-base text-center font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            }
          />
        </div>
      </div>
    </>
  )
}

export default MobileMenu

import React from "react"
import { FunctionComponent } from "react"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

const PageLayout: FunctionComponent = ({ children }) => {
  return (
    <>
      <Header />
      <main style={{ marginTop: "80px" }}>{children}</main>
      <Footer />
    </>
  )
}

export default PageLayout

import React, { FunctionComponent } from "react"
import { THeaderLink } from "../../models/header.model"
import HeaderLink from "../HeaderLink/HeaderLink"

type Props = {
  links: THeaderLink[]
  linkClasses: string
}

const HeaderLinks: FunctionComponent<Props> = ({ links, linkClasses }) => {
  return (
    <>
      {links.map((link) => (
        <HeaderLink
          key={link.name}
          link={link}
          linkClass={linkClasses + link.class}
        />
      ))}
    </>
  )
}

export default HeaderLinks

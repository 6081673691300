/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe, faHeart } from "@fortawesome/free-solid-svg-icons"

const Footer: FunctionComponent = () => {
  const handleCookieConsent = () => {
    // @ts-ignore
    window.Osano?.cm.showDrawer("osano-cm-dom-info-dialog-open")
  }

  return (
    <footer className="w-full text-gray-700 body-font">
      <div className="container flex px-5 py-5 mx-auto">
        <div className="flex flex-col items-center flex-grow text-center lg:flex-row lg:mt-0">
          <div className="flex-shrink-0 mx-auto text-center w-50 md:mx-0 lg:text-left">
            <Link
              to={"/"}
              className="flex items-center justify-center font-medium text-gray-900 title-font lg:justify-start"
            >
              <p className="font-serif text-2xl text-gray-900 text-bold">
                Die Disruptions-DNA
              </p>
            </Link>
            <p className="text-sm text-gray-700">
              <a href="https://brittadaffner.com">by Britta Daffner</a>
            </p>
          </div>
          <div className="w-full px-2 mt-4 text-sm lg:mt-0 ">
            <Link
              to={"/contact"}
              className="text-gray-700 cursor-pointer hover:text-gray-900"
            >
              Kontakt
            </Link>
          </div>
          <div className="w-full px-2 mt-4 text-sm lg:mt-0 ">
            <Link
              to={"/imprint"}
              className="text-gray-700 cursor-pointer hover:text-gray-900"
            >
              Impressum
            </Link>
          </div>
          <div className="w-full px-2 mt-4 text-sm lg:mt-0 ">
            <button
              className="text-gray-700 cursor-pointer hover:text-gray-900"
              onClick={handleCookieConsent}
            >
              Datenschutzeinstellungen
            </button>
          </div>
          <div className="w-full px-2 mt-4 text-sm lg:mt-0 ">
            <a
              href="https://www.tmeyer.me"
              className="text-gray-700 cursor-pointer hover:text-gray-900"
            >
              Made with <FontAwesomeIcon icon={faHeart} /> by Tobias Meyer
            </a>
          </div>
          <div className="w-full px-2 mt-4 lg:mt-0 lg:text-right">
            <span className="inline-flex items-center justify-center mt-2 sm:ml-auto sm:mt-0">
              <a
                href={"https://brittadaffner.com"}
                rel="noreferrer"
                className="text-gray-700 cursor-pointer hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faGlobe} />
              </a>
              <a
                href={"https://www.instagram.com/britta.daffner"}
                rel="noreferrer"
                className="ml-3 text-gray-700 cursor-pointer hover:text-gray-700"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
                </svg>
              </a>
              <a
                href={"https://de.linkedin.com/in/britta-daffner"}
                rel="noreferrer"
                className="ml-3 text-gray-700 cursor-pointer hover:text-gray-700"
              >
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  />
                  <circle cx="4" cy="4" r="2" stroke="none" />
                </svg>
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
